<template>
  <div>
    <b-list-group flush>
      <b-list-group-item v-for="x in booking" :key="x._id">
        <div class="d-flex justify-content-between">
          <div>
            <b-link :to="{ name: 'order-view', params: { id: x.order._id } }">{{
              x.order.order_id
            }}</b-link>
          </div>
          <div>{{ x.product.name }} ({{ x.price }} บาท)</div>
          <div>{{ x.start_date | day }} - {{ x.end_date | day }}</div>
          <div>
            <b-badge>{{ x.order_status_text.text }}</b-badge>
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BLink, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    BLink,
    BListGroup,
    BListGroupItem,
    BBadge,
  },
  created() {
    this.load_data();
  },
  data() {
    return {
      booking: [],
    };
  },
  props: ["member_id", "order_id"],
  methods: {
    load_data() {
      this.$http({
        method: "POST",
        url: `/order/booking/member`,
        data: {
          member_id: this.member_id,
          order_id: this.order_id,
        },
      }).then((x) => {
        this.booking = x.data.data;
      });
    },
  },
  filters: {
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>
